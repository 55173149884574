.skews{
  display: none;
}

.skew{
  position: absolute;
}

.top{
  left: -10px;
  top: 1px;
  width: calc(100% + 20px);
  height: 50px;
  transform: translateY(-100%);
}
.bottom{
  left: -10px;
  bottom: 1px;
  width: calc(100% + 20px);
  height: 50px;
  transform: translateY(100%);
}
