// FOOTER START

footer{
  padding: calc(1rem + 50px) 0; //Höhe der Schrägenillusion, da die Schräge eigentlich vom Element drüber kommt.
  background-color: $color-red;
  text-align: center;
  color: white;

  #redcord-logo{
    margin: 1rem 0;
    width: 200px;
  }

  a{
    // position: relative;
    // nav &{
    //   &:after{
    //     display: none;
    //   }
    // }
    // &:after{
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   bottom: -1px;
    //   height: 1px;
    //   width: 100%;
    //   background-color: $main-color;
    // }
    &:link{
      font-weight: 400;
      color: white;
      text-decoration: none;
    }
    &:visited{
      color: white;
    }
    &:hover{
      color: $color-hover;
      text-decoration: none;
    }
    &:active{
      color: white;
    }
  }
}

// FOOTER END
