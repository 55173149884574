@mixin responsive-grid($colcount, $xsBreakpoint:520px, $smBreakpoint:768px, $mdBreakpoint: 1024px, $lgBreakpoint: 1280px, $xlBreakpoint: 1440px ){

  .row{

    width: 100%;
    margin: 0 auto;
    // Clearfix für Row
    *zoom: 1;
    &:before,&:after {
        content: " ";
        display: table;
    }
    &:after{
      clear: both;
    }
  }

  .col{
    float: left;
  }


  @for $i from 1 through $colcount {
    $width: percentage(1 / $colcount * $i);

    .col-#{$i} {
      width: $width;
    }

    .offset-#{$i}{
      margin-left: $width;
    }
  }

  @media only screen and (min-width: $xsBreakpoint){
    @for $i from 1 through $colcount {
      $width: percentage(1 / $colcount * $i);

      .col-xs-#{$i} {
        width: $width;
      }

      .offset-xs-#{$i}{
        margin-left: $width;
      }
    }
  }

  @media only screen and (min-width: $smBreakpoint){
    @for $i from 1 through $colcount {
      $width: percentage(1 / $colcount * $i);

      .col-sm-#{$i} {
        width: $width;
      }

      .offset-sm-#{$i}{
        margin-left: $width;
      }
    }
  }
  @media only screen and (min-width: $mdBreakpoint){
    @for $i from 1 through $colcount {
      $width: percentage(1 / $colcount * $i);

      .col-md-#{$i} {
        width: $width;
      }
      .offset-md-#{$i}{
        margin-left: $width;
      }
    }
  }
  @media only screen and (min-width: $lgBreakpoint){
    @for $i from 1 through $colcount {
      $width: percentage(1 / $colcount * $i);

      .col-lg-#{$i} {
        width: $width;
      }
      .offset-lg-#{$i}{
        margin-left: $width;
      }
    }
  }
  @media only screen and (min-width: $xlBreakpoint){
    @for $i from 1 through $colcount {
      $width: percentage(1 / $colcount * $i);

      .col-xl-#{$i} {
        width: $width;
      }

      .offset-xl-#{$i}{
        margin-left: $width;
      }
    }
  }
}

@mixin relative-height($ratio){
  .content-relative-height{
    padding-top: percentage(1/$ratio);
    position: relative;

    .content{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
