#nav-wrapper{
  position: absolute;
  top: 0; // !important;
  left: 0;
  z-index: 999;
  width: 100%;

  .background-element{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 80px;
    box-shadow: 0 0 100px rgba(0,0,0,0.1);
    transform-origin: 100% 100%;
  }
  #brand-logo-start{
    position: absolute;
    width: 45%;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: inline;
    z-index: 0;
  }
}
#main-nav-pin-wrapper{
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
}

#main-nav{
  position: absolute;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  height: 100vh;
  color: black;
  z-index: 2;

  .nav-collapse{

  }

  &.mobile-start{
    .brand{
      #brand-logo{
        pointer-events: none;
        // visibility: hidden;
        opacity: 0;
      }
    }
    .nav-toggle{
      pointer-events: none;
      // visibility: hidden;
      opacity: 0;
    }
    .nav-collapse{
      position: absolute;
      top: auto;
      bottom: 0px;
      background-color: transparent;
      ul{
        padding-bottom: 2rem;
        li{
          line-height: 3rem;
          a{
            font-size: 1.3em;
          }
        }
      }
      @media only screen and (min-width: 375px){
        // ul{
        //   padding-bottom: 1rem;
        //   li{
        //     line-height: 3.8rem;
        //     a{
        //       font-size: 1.7em;
        //     }
        //   }
        // }
      }
    }
  }



  .brand{
    height: 80px;
    line-height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

    #brand-logo{
      margin-left: 2rem;
      width: 176px;
      display: inline-block;
      pointer-events: auto;
      transition: opacity 0.5s;
      opacity: 1;

      .blog & {
        //height: 55px;
        //width: 55px;
      }
    }
  }

  .nav-list{
    padding-bottom: 1rem;
  }

  ul{
    list-style: none;
    li{
      margin: 0;
      width: 100%;
    }
  }

  .nav-toggle{
      // visibility: visible;
      pointer-events: auto;
      z-index: 10;
      transition: opacity 0.5s;
      opacity: 1;
      right: 0;
      top: 0;
      vertical-align: middle;
      height: 100%;
      width: 6rem;
      padding: 0 2rem;
      position: fixed;
      .nav-icon{
        position: absolute;
        top: 32px;
        right: 2rem;
        background-color: $color-red;
        width: 26px;
        height: 2px;
        transition: transform 0.2s;
        &:before, &:after{
          position: absolute;
          content: '';
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $color-red;
          transition: transform 0.2s, opacity 0.2s;
        }

        &:before{
          top: 7px;
        }
        &:after{
          top: 14px;
        }
      }

      &.active{
        .nav-icon{
            // transform: translateY(10px);
            transform: translateY(10px) rotate(45deg);
            &:before{
              // top: 7px;
              transform: translateY(-7px);
              opacity: 0;
            }
            &:after{
              // top: 14px;
              transform: translateY(-14px) rotate(-90deg);
            }
        }
      }
    }
  .nav-collapse{
    &.opened{
      // opacity: 1 !important;
      // transform: none !important;
      // top: 80px;
      // overflow: visible;
    }
    left: 0;
    width: 100%;
    overflow: visible;
    max-height: none;
    position: fixed;
    background-color: white;
    top: 80px;
    // transform: skewY(-3deg);

    ul{
      li{
        text-align: center;
        line-height: 4rem;
        position: relative;
        z-index: 5;

        a{
          font-size: 1.4em;
          font-weight: 600;
        }

        &:after{
          content: '';
          height: 0;
          overflow: hidden;
          border-bottom: 1px solid $color-red;
          transform: rotate(-3deg);
          width: 75%;
          margin: 0 auto;
          display: block;
        }

        &:first-child{
          &:after{
            // display: none;
          }
        }

        &:last-child{
          &:after{
              position:absolute;
              border: none;
              height: 50px;
              left: 50%;
              top: 50%;
              z-index: -1;
              transform: translateX(-50%) translateY(-50%) rotate(0deg) skewY(-3deg);
              background-color: $color-red;
          }

          a:link {
            color: white;
            width: 100%;
            display: block;
          }

          /* visited link */
          a:visited {
            color: white;
          }

          /* mouse over link */
          a:hover {
            color: white;
            color: $color-light-grey;
          }

          /* selected link */
          a:active {
            color: white;
          }
        }
        /* unvisited link */
        a:link {
          // color: $color-red;
          text-decoration: none;
          text-transform: uppercase;
        }

        /* visited link */
        a:visited {
          // color: $color-red;
        }

        /* mouse over link */
        a:hover {
          color: $color-dark-grey;
        }

        /* selected link */
        a:active {
          // color: $color-red;
        }
      }
    }
  }
}

.start-position{
  .top-navigation-elements{
    position: relative;
    transform: translateY(-130px);
  }
}

@media #{$medium-up}{

  #main-nav{
    position: relative;
    display: flex;
    justify-content: space-between;
    height: auto;
    padding: 0 0 0 2rem;

    .nav-list{
      padding-bottom: 0;
    }

    .brand{
      position: relative;
      #brand-logo{
        margin-left: 0;
      }
    }

    .nav-toggle{
      display: none;
    }

    .nav-collapse{
      position: relative;
      left: auto;
      top: auto;
      width: auto;
      max-width: 1024px;
      display: block;
      background-color: transparent;

      ul{
        height: 80px;
        transition: none;
        margin-right: 0;
        padding-left: 0;
        li{
          padding: 0 1.6rem;
          text-align: left;
          border-bottom: none;
          width: auto;
          line-height: 80px;
          float: left;
          position: relative;
          text-transform: none;
          color: $color-red;
          font-size: 0.9rem;

          &:first-child{
            &:after{
              display: none;
            }
          }

          &:after{
            position: absolute;
            content: '';
            overflow: hidden;
            border-bottom: 3px solid $color-red;
            transform: rotate(-75deg);
            width: 30px;
            margin: 0 auto;
            display: block;
            left: -14px;
            top: 38px;
          }

          &:last-child{
            &:after{
              height: 40px;
              width: 100%;
              transform: translateX(-50%) translateY(-50%) rotate(0deg) skewX(-15deg);
            }
          }

          a{
            position: relative;
            font-weight: 600;
            font-size: 0.9rem;
            &:before{
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              height: 3px;
              background-color: $color-red;
              left: 0;
              bottom: -6px;
              transition: transform .3s;
              transform: scaleX(0);
            }
          }
          &.active{
            a{
              &:before{
                // transform: scaleX(1);
              }
            }
          }
        }
      }
    }
  }
  #nav-wrapper{
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 999;
    // width: 100%;

    .background-element{
      // position: fixed;
      // top: 0;
      // left: -5px;
      z-index: -1;
      // width: calc(100% + 10px);
      // height: 80px;
      // box-shadow: 0 0 200px rgba(0,0,0,0.7);
      // transform-origin: 100% 100%;
      transform: translateY(-100%);
    }
    #brand-logo-start{
      display: none;
    }
  }

  .start-position{
    .top-navigation-elements{
      position: relative;
      transform: translateY(0);
    }
  }
}
