.category-jobs{
    .back-to-home-bg{
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center top;
        background-image: url(img/physiotherapie-grzybowski-kontakt-01-mobile.jpg);
        @media #{$medium-up}{
            background-image: url(img/physiotherapie-grzybowski-kontakt-01.jpg);
            background-size: cover;
            background-position: center;
        }
    }
    #nav-wrapper{
        display: none;
    }

    .back-to-home{
        display: block;
        background-color: $color-red;
        padding: 10px 15px;
        color: white;
        position: absolute;
        top: 0;
    }
    h2{
        margin-bottom: 20px;
    }
    p{
        columns: 1;
    }
    form p{
        columns: 350px 2;
    }
}