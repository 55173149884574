$color-red: #DD0B18;

$main-font-color: #95989A;
$color-dark-grey: #303030;
$color-light-grey:    #f7f7f7;

$color-link: #303030;
$color-visited: #303030;
$color-hover: #949494; 
$color-active: #303030;
