@include responsive-grid(12);

.row{
  .col{
    padding: 1rem 0.5rem;
  }
}

@media #{$small-up}{
  .row{
    .col{
      padding: 1rem;
    }
  }
}

@media #{$medium-up}{
  .md-float-right{
    float: right !important;
  }
}
