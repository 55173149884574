form {
    display: block;
    margin-top: 0em;
}

.kontaktformular {
  input, select, textarea{
    width: 100%;
    background-color: $color-light-grey;
    border: none;
    border-bottom: 3px solid $color-red;
    padding: 0.5rem 1rem;
    outline: none;
    transition: background-color 0.5s, color 0.5s;
    color: $color-red;
    margin-bottom: 1rem;
    border-radius: 0;

    &:-webkit-autofill {
        background-color: $color-light-grey;
        -webkit-box-shadow: 0 0 0px 1000px $color-light-grey inset;
        -webkit-text-fill-color: $color-red;
    }

    &:focus{
      background-color: $color-red;
      color: white;
      &::-webkit-input-placeholder {
        color: white;
      }
      &:-moz-placeholder {
        color: white;
      }
      &::-moz-placeholder {
        color: white;
      }
      &:-ms-input-placeholder {
        color: white;
      }
    }
  }
}

.kontaktformular input[type=submit]{
    background-color: $color-red;
    color: white;
    border: none;

}

.kontaktformular input[type=checkbox]{
  width: auto;
  margin-right: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  top: -1px;
}

#sendContactMail{
  transition: color 0.3s;
}

.kontaktformular input, .kontaktformular select {
}

.kontaktformular textarea {
  resize: none;
}

.parsley-error{
  color: $color-red !important;
  animation: input-error 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes input-error {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.parsley-errors-list{
  list-style: none;
  margin: 0;
  padding: 0;
  color: $color-red;
}


.wpcf7-form{
  input, select,textarea{
    border-radius: 0;
  }
  input:not([type=checkbox]):not([type=radio]),textarea{
    width: 100%;
  }
  margin-top: 30px;
  p{
    //columns: 1 !important;
  }
  a:link{
    font-weight: 500;
    text-decoration: underline;
  }
  a:hover{
    font-weight: 500;
  }
  label, h4{
    display: inline-block;
    padding-top: 10px;
    padding-left: 10px;
    font-size: 0.925rem;
    font-weight: 500;
    input{
      font-weight: 400;
    }
    span{
      display:inline-block;
      margin-top: 3px;
      padding-bottom: 3px;
      font-weight: 500;
    }
    input:not([type=checkbox]), select, textarea{
      margin-left: -10px;
    }
    &:has(input[type=checkbox]){
      padding-left: 0;
    }
  }
  .wpcf7-list-item{
    display: flex !important;
    align-items: flex-start;
    [type=checkbox]{
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  h4{
    padding-bottom: 3px;
  }
  .qualifications-label{
    padding-top: 20px;
  }
  input[type=text],input[type=email],input[type=tel], textarea, select, checkbox{
    font-size: 1rem;
    border: none;
    padding: 0 15px;
    height: 42px;
    background-color: #f5f5f5;
    border-bottom: 2px solid #e4e4e4;
    transition: border-bottom .3s;
  }
  p{
    &:has([name=term_and_conditions]){
      columns: 1;
    }
  }
  [name=term_and_conditions] + span{
    display: inline;
  }
  input[type=text],input[type=email],input[type=tel], select, checkbox {
    line-height: 42px;
  }
  input[type=text],input[type=email],input[type=tel], textarea,{
    appearance: none;
    &:focus{
      outline: none;
      border-bottom: 2px solid #DD0B18;
    }
  }
  .wpcf7-checkbox{
    padding-top: 15px;
    padding-bottom: 30px;
    .wpcf7-list-item{
      &.first{
        //padding-top: 15px;
      }
      display: block;
      padding: 5px 0;
    }
  }
  textarea{
    height: calc((42px - 1rem) + 1.25rem * 5);
    line-height: 1.25rem;
    padding: calc((42px - 1rem)/2) 15px;
  }
  input[type=submit]{
    padding: 10px 18px;
    background-color: #DD0B18;
    color: white;
    border: none;
    outline: none;
    margin-top: 15px;
    //margin-left: 11px;
  }
}