*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  font-family: Open Sans;
  background-color: white;
  overflow-x: hidden;
}

body{
  opacity: 1;
  transition: opacity 0.2s;
  pointer-events: all;
}

.hidden-body{
  opacity: 0;
  pointer-events: none;
}

.back-to-home{
  display: none;
}

ul{
  list-style: none;
}

//LINKS

a{
  // position: relative;
  // nav &{
  //   &:after{
  //     display: none;
  //   }
  // }
  // &:after{
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   bottom: -1px;
  //   height: 1px;
  //   width: 100%;
  //   background-color: $main-color;
  // }
  &:link{
    font-weight: 300;
    color: $color-link;
    text-decoration: none;
  }
  &:visited{
    color: $color-visited;
  }
  &:hover{
    color: $color-hover;
    text-decoration: none;
  }
  &:active{
    color: $color-active;
  }
}

strong{
  font-weight: 400;
  border-bottom: 1px solid $color-red;
}

//

#outer-wrapper{
  position: relative;
  z-index: 10;
}

.section-image{
  height: 75vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: -1;

  &#praxis-bg{
    height: calc(90vh + 140px);
    background-image: url(img/physiotherapie-grzybowski-praxis-01-mobile.jpg);
  }
  &#leistungen-bg{
    background-position: center top;
    background-image: url(img/physiotherapie-grzybowski-leistungen-01-mobile.jpg);
  }

  &#neurac-bg{
    background-image: url(img/physiotherapie-grzybowski-neurac-01-mobile.jpg);
  }

  &#praevention-bg{
    background-image: url(img/physiotherapie-grzybowski-praevention-01-mobile.jpg);
  }

  &#kontakt-bg{
    background-position: center top;
    background-image: url(img/physiotherapie-grzybowski-kontakt-01-mobile.jpg);
  }
}

@supports (-webkit-touch-callout: none) {
  .section-image{
    background-attachment: scroll !important;
  }
}

.skew-parent{
  position: relative;
  width: 100%;
  // z-index: 10;
}

.content{
  width: 100%;
  max-width: 1024px;
  padding: 0 2rem;
  margin: 0 auto;
  position: relative;

  p{
    columns: 350px 2;
    column-gap: 2rem;
    font-weight: 300;
    line-height: 1.4rem;
  }

  h4 + ul {
    margin-top: -0.35rem;
  }

  ul{
    margin: 1rem 2rem;
    li{
      padding: 0.35rem 0;
      font-weight: 300;
      line-height: 1.4rem;
      position: relative;

      &:before{
        position: absolute;
        top: 1.05rem;
        left: -15px;
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: $color-red;
      }
    }
  }
}

.content-section{
  // margin-bottom: -30px;
}

div.content{
  padding-top: 2.5rem;

  &:before{
    position: absolute;
    top: 0;
    content: '';
    display: block;
    height: 3px;
    width: 4rem;
    background-color: $color-red;
  }
}

.section-headline-wrapper{
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  transform: translateY(50px);
  padding-bottom: 50px;
  z-index: -1;

  h2{
    color: white;
    display: block;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 600;
  }

  .background-wrapper{
    z-index: -1;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.3;
  }
}

.content-wrapper{
  background-color: white;
  padding: 2rem 0;
}

.white-fill{
  fill: #fff;
}

.white-bg{
  background-color: #fff;
}


.dark-fill{
  fill: rgb(0,0,0);
}

.dark-bg{
  background-color: rgb(0,0,0);
}

h3{
  margin: 0 0 .75em;
  font-weight: 400;
}

h4{
  margin: 1.5em 0 .75em;
}

.adress-list{
  list-style: none;
  margin: 0;
  padding: 0;
}

#impressum, #disclaimer, #datenschutz{
  display: none;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  height: 100vh;
  padding: 2em;
  background-color: white;
  text-align: left;
  overflow-y: scroll;
  overflow-x: hidden;


  .hidden-h1{
    font-size: 3rem;
    margin: 2rem 0 1rem;
    color: $color-red;
  }
  .hidden-h2{
    font-size: 2rem;
    margin: 2rem 0 1rem;
    color: $color-red;
  }
  .hidden-h3{
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
    color: $color-red;
  }
}
#close-impressum, #close-disclaimer, #close-datenschutz{
  position: absolute;
  right: 5px;
  top: 10px;
  width: 30px;
  height: 30px;
  &:after{
    content: '';
    position: absolute;
    width: 30px;
  height: 3px;
  left: 0;
  top: 15px;
  background-color: $color-dark-grey;
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  }
  &:before{
    position: absolute;
    top: 15px;
    left: 0;
    content: '';
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    width: 30px;
    height: 3px;
    background-color: $color-dark-grey;
  }
}

// CONTENT IMAGE START

.content-image{
  display: block;
  width: 100%;
  margin: 2rem auto;
  height: auto;

  & + .content-image{
    margin-top: 0rem; 
  }
}

iframe{
  margin: 2rem auto;
}

//CONTENT IMAGE END

//HEADLINEANIMATIONS START
#headline-praxis{
  // transform: translateY(50px);
}
//HEADLINEANIMATIONS END


@media #{$small-up}{

}

@media #{$medium-up}{

  .content-image{
    width: 90%;
  }
  .section-image{
    background-attachment: fixed;

    .edge &, .msie &, .trident &{
      background-attachment: scroll;
      &#leistungen-bg{
        background-position: center center;
      }
    }
    &#praxis-bg{
      background-image: url(img/physiotherapie-grzybowski-praxis-01.jpg);
    }
    &#leistungen-bg{
      background-position: center top;
      background-image: url(img/physiotherapie-grzybowski-leistungen-01.jpg);
    }

    &#neurac-bg{
      background-image: url(img/physiotherapie-grzybowski-neurac-01.jpg);
    }

    &#praevention-bg{
      background-image: url(img/physiotherapie-grzybowski-praevention-01.jpg);
    }

    &#kontakt-bg{
      background-position: center top;
      background-image: url(img/physiotherapie-grzybowski-kontakt-01.jpg);
    }
  }
}
